import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from "../../login/service/auth.service";
//import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  //@BlockUI() blockUI: NgBlockUI;
  constructor(private authenticationService: AuthService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isApiUrl = false
    environment.url.backEndInit.forEach((urlInit)=>{
      if(request.url.startsWith(urlInit)){
        isApiUrl=true;
      }
    })
    if(!isApiUrl){
      return next.handle(request);
    }
    const isLoggedIn = this.authenticationService.isAllAuthenticated();
    if(isLoggedIn){
      const clonedRequest = this.attachTokenToRequest(request, this.authenticationService.getAutorizationToken());
      return next.handle(clonedRequest);
    }else{
      return next.handle(request);
    }
    
  }
  private attachTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }




}