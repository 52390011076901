import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AlertService } from '../../utils/message-alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  providers: [MessageService]
})
export class AlertComponent implements OnInit {

  constructor(
    private message: MessageService,
    private alertMessage: AlertService,
  ) {
    alertMessage.message.subscribe((data: any) => {
      if (data) {
        this.ShowMessage(data)
      }
    })
   }

  ngOnInit() {
  }
  ShowMessage(Message: any) {
    this.message.clear()
    this.message.add(Message);
  }

}
