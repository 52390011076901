import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../../environments/environment'
import { ObjetResponse, SettingResponse } from "../data/response";
import { Observable, map, of } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class SettingService {
    private readonly CACHE_TTL = 10 * 60 * 1000; // 10 minutos en milisegundos
    private readonly timestampKey = 'expiryTimeSettings';
    constructor(private http:HttpClient){}

    getSetting(zoneId:string|number,isRefresh:boolean=false): Observable<ObjetResponse<SettingResponse>>{

        const setting =  this.getSharedDataSetting(zoneId.toString())
        const expiryTime = this.getExpiryTime()
        const now = Date.now();
        if(!isRefresh){
            if((setting &&  now<expiryTime)){
                return of(setting)
            }
        }

        return this.http.get<ObjetResponse<SettingResponse>>
        (environment.url.util_banckEnd+"/setting/user/zone/"+zoneId).pipe(
            map((resp) => {
                const now = Date.now();
                const expiry = now + this.CACHE_TTL;
                
                localStorage.setItem(zoneId.toString(), JSON.stringify(resp));
                localStorage.setItem(this.timestampKey, JSON.stringify(expiry));
                return resp
            })
        )
    }

    getSharedDataSetting(zoneId) : ObjetResponse<SettingResponse>{
        let setting = (JSON.parse(localStorage.getItem(zoneId.toString())) as ObjetResponse<SettingResponse>)
        if(!setting) {
            return null
        }
        return setting
    }

    private getExpiryTime(){
        return Number(JSON.parse(localStorage.getItem(this.timestampKey)))
    }
    getIpId() {
        return this.http.get('https://api.ipify.org/');
    }
}