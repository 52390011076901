import { SharedDataService } from './../../commons/shared/shared-data.service';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { ObjetResponse } from '../../commons/data/response';
const serviceToken = 'CognitoIdentityServiceProvider.';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SuccessResponse, ErrorResponse } from './response';
import { ClientMetaData } from '@aws-amplify/auth/lib-esm/types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  initNameToken = serviceToken + environment.userPoolWebClientId + '.'; //'CognitoIdentityServiceProvider.sn8a6iuosla634lfs21127p0a.';
  dataToken = '';
  private cognitoUser: CognitoUser & {
    challengeParam: { USERNAME: string; phone: string; uuid: string; clientId:string; };
  };
  private selectedStoreSubject = new BehaviorSubject<any>(null);
  selectedStore$ = this.selectedStoreSubject.asObservable();

  private selectedBrandSubject = new BehaviorSubject<any>(null);
  selectedBrand$ = this.selectedBrandSubject.asObservable();
  // Get access to window object in the Angular way
  private window: Window;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private sharedService: SharedDataService
  ) {
    this.window = this.document.defaultView;
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    this.selectedStoreSubject.next(selectedStore);

    const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
    this.selectedBrandSubject.next(selectedBrand);
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
    if (key === 'selectedStore') {
      this.selectedStoreSubject.next(value);
    }
    if (key === 'selectedBrand') {
      this.selectedBrandSubject.next(value);
    }
  }

  public async signIn(sms: string, pw?: string, clientMetadata?: ClientMetaData) {
    try {
      this.cognitoUser = await Auth.signIn(sms,pw,clientMetadata);
      console.log(this.cognitoUser);
      // this.sharedService.setChallengeParams(this.cognitoUser);
      return {
        success: true,
        challengeName: this.cognitoUser.challengeName,
        challengeParam : this.cognitoUser.challengeParam
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error: error.message,
      };
    }
  }

  public async signOut() {
    await Auth.signOut();
  }

  public async answerCustomChallenge(answer: string) {
    console.log(this.cognitoUser);
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(
      this.cognitoUser,
      answer,
      this.cognitoUser.challengeParam
    );
    return this.isAuthenticated();
  }

  public getPublicChallengeParameters() {
    return this.cognitoUser.challengeParam;
  }

  public async signUp(sms: string, fullName: string) {
    const params = {
      username: sms,
      password: this.getRandomString(30),
      attributes: {
        name: fullName,
      },
    };
    await Auth.signUp(params);
  }

  private getRandomString(bytes: number) {
    const randomValues = new Uint8Array(bytes);
    this.window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(this.intToHex).join('');
  }

  private intToHex(nr: number) {
    return nr.toString(16).padStart(2, '0');
  }

  public async isAuthenticated() {
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  public isAllAuthenticated() {
    this.dataToken = '';
    const nameToken = this.initNameToken + this.getNameTokenId() + '.idToken';
    this.dataToken = localStorage.getItem(nameToken);

    if (this.dataToken != null) {
      const decode = jwt_decode(this.dataToken) as any;
      const tokenExpire = Number(decode.exp as string);
      const now = Number(new Date().getTime().toString().substring(0, 10));
      return tokenExpire > now;
    } else {
      return false;
    }
  }

  public async getUserDetails() {
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    return await Auth.userAttributes(this.cognitoUser);
  }

  getAutorizationToken() {
    this.dataToken = '';
    const nameToken = this.initNameToken + this.getNameTokenId() + '.idToken';
    this.dataToken = localStorage.getItem(nameToken);
    //console.log("TOKEN" , this.dataToken)
    return localStorage.getItem(nameToken);
  }

  getUserDataToken() {
    // this.dataToken="";
    const nameToken = this.initNameToken + this.getNameTokenId() + '.userData';
    return nameToken;
  }

  getNameTokenId() {
    let nameTokenid = this.initNameToken + 'LastAuthUser';

    return localStorage.getItem(nameTokenid);
  }

  resetSecurityObject(): void {
    this.signOut();
  }

  async getCurrentToken(): Promise<string | null> {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken();
      const token = accessToken.getJwtToken();
      return token;
    } catch (error) {
      console.log('Error al obtener el token:', error);
      return null;
    }
  }
  async refreshToken() {
    const currentToken = await this.getCurrentToken();
    if (currentToken) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshedUser = cognitoUser.refreshSession(
          user.signInUserSession.refreshToken
        );
        const refreshedToken =
          refreshedUser.signInUserSession.accessToken.jwtToken;
        console.log('Token actualizado:', refreshedToken);
      } catch (error) {
        console.log('Error al actualizar el token:', error);
      }
    }
  }

  registerUser(formData: any, selectCountryCode) {
    const bodyRequest = {
      name: formData.nombre,
      secondName: formData?.segundo_nombre??"",
      surname: formData.apellido,
      secondSurname: formData.segundo_apellido,
      email: formData.correo,
      phone: selectCountryCode + formData.numero,
      countryCode: selectCountryCode,
      documentType: formData.tipo_documento,
      documentValue: formData.numero_documento
    };

    return this.http
      .post<SuccessResponse | ErrorResponse>(
        environment.url.backEnd + '/user/blankBrand/user-app',
        bodyRequest
      )
      .pipe(
        map((response) => {
          if ('meta' in response) {
            return response;
          } else {
            throw response;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //https://dev-api.tres22.net/delivery-cognito/login/resend  

  resendCodeSMS(uuid: any, phoneNumber, clientId, type){
    const bodyRequest = {
      uuid: uuid,
      phone_number: phoneNumber,
      clientId: clientId,
      typeChannel: type
    };
    return this.http.post<ObjetResponse<any>>(environment.url.smsBackEnd + '/login/resend',bodyRequest)
  }

  resendSMS(uuid: any, phoneNumber, clientId) {
    const bodyRequest = {
      uuid: uuid,
      phone_number: phoneNumber,
      clientId: clientId,
    };
    return this.http.post<ObjetResponse<any>>(
      environment.url.util_banckEnd + '/sms/resend/user',
      bodyRequest
    );
  }
  getParameterToken(parameter){
    try {
      const tokenId=this.getAutorizationToken()
      const decode = jwt_decode(tokenId) as any
      console.log(decode[parameter])
      return decode[parameter]
    } catch (error) {
      return null 
    }
  }

}
