import { PaymentModule } from './modules/payment/payment.module';
import { MyOrderModule } from './modules/my-order/my-order.module';
import { MyOrderComponent } from './modules/my-order/my-order.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticated, IsNotAuthenticated } from './modules/commons/guard/auth-guard';


const routes: Routes = [
  {
    path: 'finish',
    // canActivate:[IsAuthenticated],
    loadChildren: () => import('./modules/finish/finish.module').then((m) => m.FinishModule)
  },
  {
    path: 'tracking/:uuid',
    loadChildren: () => import('./modules/tracking/tracking.module').then(m => m.TrackingModule),
  },
  {
    path: 'chat/:uuid',
    //canActivate: [IsAuthenticated],
    loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'address',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./modules/address/address.module').then(m => m.AddressModule)
  },
  {
    path: 'credit-card',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./modules/credit-card/credit-card.module').then(m => m.CreditCardModule)
  },
  {
    path: 'store-list',
    loadChildren: () => import('./modules/store-list/store-list.module').then(m => m.StoreListModule)
  },
  {
    path: 'login',
    canActivate: [IsNotAuthenticated],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'my-order',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./modules/my-order/my-order.module').then(m => m.MyOrderModule)
  },
  {
    path: 'payment',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'store/:customPath',
    loadChildren: () => import('./modules/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'store/:customPath/product/:id',
    loadChildren: () => import('./modules/product-detail/product-detail.module').then(m => m.ProductDetailModule)
  },
  {
    path: 'store/:customPath/cart',
    loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'catalog',
    loadChildren: () => import('@catalog/catalog.module').then(m => m.CatalogModule)
  },
  {
    path: 'user',
    // canActivate: [IsAuthenticated],
    loadChildren: () => import('@user/user.module').then(m => m.UserModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/store-list/store-list.module').then(m => m.StoreListModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled', bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
