import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { JWTInterceptor } from './modules/commons/interceptor/jwt-interceptor';
import { AlertModule } from './modules/alert/alert.module';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CommonModule } from '@angular/common';
import { PixelModule } from 'ngx-pixels';
import { environment } from '@environments/environment';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogUpdateWebModule } from './modules/dialogUpdateWeb/dialogUpdateWeb.module';
import { DialogUpdateWebComponent } from './modules/dialogUpdateWeb/dialogUpdateWeb.component';
import { DialogModule } from 'primeng/dialog';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    DialogUpdateWebComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    CommonModule,
    FormsModule,
    HttpClientModule,
    SelectButtonModule,
    TabViewModule,
    AlertModule,
    PixelModule.forRoot({ enabled: true, fbPixelId: environment.GOOGLE.PIXELID}),
    DialogUpdateWebModule,
    DialogModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
