export const STATUS_PAYMENT={
    ACCEPTED:1,
    REFUSED:2,
    PENDING:3,
    FAILED:4,
    REVERSED:6,
    DETAINED:7,
    INITIATED:8,
    EXPIRED:9,
    ABANDONED:10,
    CANCELLED:11
}
export const EVENTS={
    PAGE_VIEW :'PageView',
    SELECT_STORE:'select_store',
    ADD_CART:'AddToCart',
    ACCESS_USER:'Accesse_User',
    SET_ORDER:'CompleteRegistration'
}

export const STATUS_PAYMENT_NUVEI={
    VALIDATE:'valid',
    REVIEW:'review',
    REJECTED:'rejected',    
}