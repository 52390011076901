import { Component, ViewChild } from '@angular/core';
import { GlobalService } from './shared/global.service';
import { Pagination } from './models';
import { SettingService } from './modules/commons/service/setting.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EVENTS } from './utils/constant';
import { UtilService } from './utils/util.service';
import { join } from 'path';
import { readFileSync } from 'fs';
import { interval, map, Observable, switchMap } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogUpdateWebComponent } from './modules/dialogUpdateWeb/dialogUpdateWeb.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'web-delivery-marca-blanca';
  @ViewChild(DialogUpdateWebComponent) dialogUpdate!: DialogUpdateWebComponent;
  pagination: Pagination = {
    page: 1,
    size: 10,
    totalRecords: 0,
    totalNumberPages: 0,
  };
  private previousVersion: string | null = null;
  private currentVersion: string | null = null
  constructor(
    private servi:SettingService,
    private utilServices:UtilService,
    private http:HttpClient
  ) {
  }
  async ngOnInit() {
    // this.servi.getIpId().subscribe((ip:any)=>{
    //   console.log(ip)
    //   this.utilServices.pixcel(EVENTS.PAGE_VIEW,{content_ids:[ip],content_name:ip})
    //   localStorage.setItem('ip',JSON.stringify(ip))
    // },(erro:HttpErrorResponse)=>{
    //   if(erro.status==200){
    //     localStorage.setItem('ip',JSON.stringify(erro.error.text))
    //   }else{
    //     console.log(erro)

    //   }
    // })
    // interval(10000)
    // .pipe(switchMap(() => this.loadVersion()))
    // .subscribe((version) => {
    //   if (this.previousVersion && this.previousVersion !== version) {
    //     console.log(`La versión ha cambiado de ${this.previousVersion} a ${version}`);
    //     this.dialogUpdate.showMessage();
    //   }
    //   this.previousVersion = version;
    // });
  }

  private loadVersion(): Observable<string | null> {
    return this.http.get<{ version: string }>('assets/version.json').pipe(
      map((data) => {
        this.currentVersion = data.version;
        console.log("VERSION::::",this.currentVersion)
        return this.currentVersion;
      })
    );
  }



}
