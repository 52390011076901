import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private phoneNumberSource = new BehaviorSubject<string>('');
  currentPhoneNumber = this.phoneNumberSource.asObservable();

  private phoneNumberSourceRegister = new BehaviorSubject<string>('');
  currentphoneNumberRegister = this.phoneNumberSourceRegister.asObservable();

  private codephoneNumberSourceRegister = new BehaviorSubject<string>('');
  codecurrentphoneNumberRegister = this.codephoneNumberSourceRegister.asObservable();

  private storageKey: string = 'challengeParams';

  constructor() { }

  updatePhoneNumber(number: string) {
    this.phoneNumberSource.next(number);
  }

  updatePhoneRegisterNumber(number: string) {
    this.phoneNumberSourceRegister.next(number);
  }

  updateCodePhoneRegisterNumber(code: string) {
    this.codephoneNumberSourceRegister.next(code);
  }

  setChallengeParams(data: any): void {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  getChallengeParams(): any {
    const storedData = localStorage.getItem(this.storageKey);
    return storedData ? JSON.parse(storedData) : null;
  }

  clearChallengeParams(): void {
    localStorage.removeItem(this.storageKey);
  }
}