import { Injectable } from "@angular/core";
import { PixelService } from "ngx-pixels";
import { PixelEventProperties } from "ngx-pixels/lib/pixel.models";

@Injectable({
    providedIn: 'root'
})
export class UtilService{
    constructor(private pixel: PixelService){}
    pixcel(event,data:PixelEventProperties){
        this.pixel.track(event,data)
    }
    custonPixcel(event,data:any){
        this.pixel.trackCustom(event,data)
    }
}