import * as dataDefault from '../assets/data/data.json'

export const environment = {
    production: false,
    url:{
         backEndInit : [
          'https://dev-api.tres22.net/admin-zone',
          "https://dev-api.tres22.net/utils",
          'https://dev-api.tres22.net/user/address',
          'https://dev-api.tres22.net/user/order',
          'https://dev-api.tres22.net/user/user/delivery/price',
          'https://dev-api.tres22.net/user/user/opinion/blankBrand',
          'https://dev-api.tres22.net/payment',
          'https://dev-api.tres22.net/user/zone/verify/gps',
          'https://dev-api.tres22.net/coupon',
          'https://dev-api.tres22.net/user/store/zone',
          'https://dev-api.tres22.net/delivery-cognito',
          'https://dev-api.tres22.net/user/user/complaint',
          'https://dev-api.tres22.net/user/user/profile',
          'https://dev-api.tres22.net/delivery-order/order/zone',
          'https://dev-api.tres22.net/delivery-zone',
          'https://dev-api.tres22.net/user',
          'https://dev-api.tres22.net/delivery-directory',
          'https://dev-api.tres22.net/delivery-store',
          'https://dev-api.tres22.net/delivery-order',
          "https://dev-api.tres22.net/delivery-invoice"
      ],
      backEndOrder: 'https://dev-api.tres22.net/delivery-order',
      neoBackEnd: 'https://dev-api.tres22.net/delivery-store',
      smsBackEnd: 'https://dev-api.tres22.net/delivery-cognito',
      backEnd: 'https://dev-api.tres22.net/user',
      backEndZone: 'https://dev-api.tres22.net/delivery-zone',
      coupon_backEnd: 'https://dev-api.tres22.net/coupon',
      payment_backEnd: 'https://dev-api.tres22.net/payment',
      util_banckEnd:"https://dev-api.tres22.net/utils",
      tracking: 'https://dev-api.tres22.net/tracking',
      order: 'https://dev-api.tres22.net/user/order',
      backEndDirectory: 'https://dev-api.tres22.net/delivery-directory',
      invoice: "https://dev-api.tres22.net/delivery-invoice"
    },
    userPoolWebClientId: '2cq9sitvq8ul9euefj7i69dbi1',
    awsConfig:{
      region: 'us-west-1',
      cognito:{
        userPoolId: 'us-west-1_tymtvmU0V'
      }
    },
    config_nuvie:{
      APP_CODE_CLIENT:"DV-TRES22SAS-STG-CO-CLIENT",
      APP_KEY_CLIENT:"KjPy8lAeu0YTGzvMK5PENXnAlaeEYX",
      STG:"stg"
    },
    mqttServer:{
      url:"devwss.tres22.net",
      port_ws:443,
      port_mqtt:1883,
      path:"/",
      user:"socket",
      pwd:"socket",
      useSSL:true
    },
    centermap:{
      lat: 10.96854,
      lng: -74.78132
    },
    conuntryCode:'PE',
    cityCenterPoint: {
      lat: -12.0621065,
      lng: -77.0365256
    },
    GOOGLE:{
      APIKEY: 'AIzaSyDjv5HAoqzn8-kMHLFAdCF2XbHZ2vUmI8Y',
      PIXELID:'990183989231956'
    },
    numberFormat: {
      decimalSeparator: ',',
      thousandsSeparator: '.',
      decimalPlaces: 0
    },
    DATA_DEFAULT:dataDefault,
    zone:{
      default:"4",
    },
    currency:{
      simbol : "S/"
    }
};
